import React from 'react'
import { sanityImageUrl, urlWithSearchParamsHandler, prepareParagraph } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ comparison }) => {
  if (!comparison || comparison.isHide) return null

  return (
    <div className={styles.comparison} id="comparison">
      <div className={styles.wrap}>
        <p className={styles.titleSmall}>{comparison.smallTitle}</p>
        <h2 className={styles.title}>{comparison.title}</h2>

        <div className={styles.desktop}>
          <div className={styles.items}>
            <div className={styles.item}>
              <img
                src={sanityImageUrl(comparison.bf?.image)}
                className={styles.image}
                alt={comparison.bf?.image?.caption}
              />
            </div>

            <div className={styles.vs}>
              <p className={styles.top}>{comparison.vs}</p>
            </div>

            <div className={styles.item}>
              <img
                src={sanityImageUrl(comparison.viagra?.image)}
                className={styles.image}
                alt={comparison.viagra?.image?.caption}
              />
            </div>
          </div>

          <div className={styles.items}>
            <div className={styles.item}>
              <p className={styles.name}>{comparison.bf?.title}</p>
            </div>

            <div className={styles.vs} />

            <div className={styles.item}>
              <p className={styles.name}>{comparison.viagra?.title}</p>
            </div>
          </div>

          <div className={styles.items}>
            <div className={styles.item}>
              <div className={styles.included}>
                {comparison.bf?.included &&
                  comparison.bf.included.map((item, index) => (
                    <p className={styles.includedItem} key={index}>
                      {item}
                    </p>
                  ))}
              </div>
            </div>

            <div className={styles.vs} />

            <div className={styles.item}>
              <div className={styles.included}>
                {comparison.viagra?.included &&
                  comparison.viagra.included.map((item, index) => (
                    <p className={styles.includedItem} key={index}>
                      {item}
                    </p>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.items}>
            <div className={styles.item}>
              <p className={styles.description}>{comparison.bf?.Description}</p>
            </div>

            <div className={styles.vs} />

            <div className={styles.item}>
              <p className={styles.description}>{comparison.viagra?.Description}</p>
            </div>
          </div>

          <div className={styles.items}>
            <div className={styles.item}>
              <div className={styles.features}>
                {comparison.bf?.features &&
                  comparison.bf.features.map((feature, index) => (
                    <>
                      {(index === 1 || index === 2) && <div className={styles.featuresBorder} />}
                      <div className={styles.featuresItem} key={feature._key}>
                        <p className={styles.featuresTitle}>{feature.title}</p>
                        <div className={styles.featuresInfo}>
                          <img
                            src={sanityImageUrl(feature.image)}
                            className={styles.featuresImage}
                            alt={feature.image?.caption}
                          />
                          <p className={styles.featuresValue}>{feature.value}</p>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              <div className={styles.benefits}>
                {comparison.bf?.benefits &&
                  comparison.bf.benefits.map((benefit, index) => (
                    <p className={styles.benefitsItem} key={index}>
                      {prepareParagraph(benefit)}
                    </p>
                  ))}
              </div>
            </div>

            <div className={styles.vs} />

            <div className={styles.item}>
              <div className={styles.features}>
                {comparison.viagra?.features &&
                  comparison.viagra.features.map((feature, index) => (
                    <>
                      {(index === 1 || index === 2) && <div className={styles.featuresBorder} />}
                      <div className={styles.featuresItem} key={feature._key}>
                        <p className={styles.featuresTitle}>{feature.title}</p>
                        <div className={styles.featuresInfo}>
                          <img
                            src={sanityImageUrl(feature.image)}
                            className={styles.featuresImage}
                            alt={feature.image?.caption}
                          />
                          <p className={styles.featuresValue}>{feature.value}</p>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              <div className={styles.benefits}>
                {comparison.viagra?.benefits &&
                  comparison.viagra.benefits.map((benefit, index) => (
                    <p className={styles.benefitsItem} key={index}>
                      {prepareParagraph(benefit)}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.mobile}>
          <div className={styles.items}>
            <div className={styles.item}>
              <img
                src={sanityImageUrl(comparison.bf?.image)}
                className={styles.image}
                alt={comparison.bf?.image?.caption}
              />
              <p className={styles.name}>{comparison.bf?.title}</p>
              <div className={styles.included}>
                {comparison.bf?.included &&
                  comparison.bf.included.map((item, index) => (
                    <p className={styles.includedItem} key={index}>
                      {item}
                    </p>
                  ))}
              </div>
              <p className={styles.description}>{comparison.bf?.Description}</p>
              <div className={styles.features}>
                {comparison.bf?.features &&
                  comparison.bf.features.map((feature, index) => (
                    <>
                      {(index === 1 || index === 2) && <div className={styles.featuresBorder} />}
                      <div className={styles.featuresItem} key={feature._key}>
                        <p className={styles.featuresTitle}>{feature.title}</p>
                        <div className={styles.featuresInfo}>
                          <img
                            src={sanityImageUrl(feature.image)}
                            className={styles.featuresImage}
                            alt={feature.image?.caption}
                          />
                          <p className={styles.featuresValue}>{feature.value}</p>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              <div className={styles.benefits}>
                {comparison.bf?.benefits &&
                  comparison.bf.benefits.map((benefit, index) => (
                    <p className={styles.benefitsItem} key={index}>
                      {prepareParagraph(benefit)}
                    </p>
                  ))}
              </div>
            </div>

            <p className={styles.middle}>{comparison.vs}</p>

            <div className={styles.item}>
              <img
                src={sanityImageUrl(comparison.viagra?.image)}
                className={styles.image}
                alt={comparison.viagra?.image?.caption}
              />
              <p className={styles.name}>{comparison.viagra?.title}</p>
              <div className={styles.included}>
                {comparison.viagra?.included &&
                  comparison.viagra.included.map((item, index) => (
                    <p className={styles.includedItem} key={index}>
                      {item}
                    </p>
                  ))}
              </div>
              <p className={styles.description}>{comparison.viagra?.Description}</p>
              <div className={styles.features}>
                {comparison.viagra?.features &&
                  comparison.viagra.features.map((feature, index) => (
                    <>
                      {(index === 1 || index === 2) && <div className={styles.featuresBorder} />}
                      <div className={styles.featuresItem} key={feature._key}>
                        <p className={styles.featuresTitle}>{feature.title}</p>
                        <div className={styles.featuresInfo}>
                          <img
                            src={sanityImageUrl(feature.image)}
                            className={styles.featuresImage}
                            alt={feature.image?.caption}
                          />
                          <p className={styles.featuresValue}>{feature.value}</p>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              <div className={styles.benefits}>
                {comparison.viagra?.benefits &&
                  comparison.viagra.benefits.map((benefit, index) => (
                    <p className={styles.benefitsItem} key={index}>
                      {prepareParagraph(benefit)}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
