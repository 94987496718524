import React from 'react'
import { sanityImageUrl, prepareParagraph } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ ingredients }) => {
  if (!ingredients || ingredients.isHide) return null

  return (
    <div className={styles.ingredients} id="ingredients">
      <div className={styles.wrap}>
        <p className={styles.titleSmall}>{ingredients.smallTitle}</p>
        <h2 className={styles.title}>{ingredients.title}</h2>
        <p className={styles.description}>{ingredients.description}</p>
        <div className={styles.list}>
          <div className={styles.listBf}>
            <p className={styles.listTitle}>{ingredients.bloodFlowIngredientsTitle}</p>
            <div className={styles.items}>
              {ingredients.items &&
                ingredients.items.map(ingredient => (
                  <div className={styles.item} key={ingredient._key}>
                    <div className={styles.itemTop}>
                      <img
                        src={sanityImageUrl(ingredient.image)}
                        className={styles.image}
                        alt={ingredient.image?.caption}
                      />
                      <p className={styles.name}>{ingredient.title}</p>

                      <div className={styles.faq}>
                        {ingredient.faq &&
                          ingredient.faq.map(faq => (
                            <div key={faq._key}>
                              <p className={styles.faqQuestion}>{faq.question}</p>
                              <p className={styles.faqanswer}>{prepareParagraph(faq.answer)}</p>
                            </div>
                          ))}
                      </div>
                    </div>

                    {ingredient.buttonText && ingredient.buttonUrl && (
                      <Link to={ingredient.buttonUrl} className={styles.link}>
                        {ingredient.buttonText}
                      </Link>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.listVs}>
            <p className={styles.listVsTitle}>{ingredients.vs}</p>
          </div>
          <div className={styles.listViagra}>
            <p className={styles.listTitle}>{ingredients.ViagraIngredientsTitle}</p>
            {ingredients.viagra && (
              <div className={styles.item}>
                <div className={styles.itemTop}>
                  <img
                    src={sanityImageUrl(ingredients.viagra.image)}
                    className={styles.image}
                    alt={ingredients.viagra.image?.caption}
                  />
                  <p className={styles.name}>{ingredients.viagra.title}</p>

                  <div className={styles.faq}>
                    {ingredients.viagra.faq &&
                      ingredients.viagra.faq.map(faq => (
                        <div key={faq._key}>
                          <p className={styles.faqQuestion}>{faq.question}</p>
                          <p className={styles.faqanswer}>{prepareParagraph(faq.answer)}</p>
                        </div>
                      ))}
                  </div>
                </div>

                {ingredients.viagra.buttonText && ingredients.viagra.buttonUrl && (
                  <Link to={ingredients.viagra.buttonUrl} className={styles.link}>
                    {ingredients.viagra.buttonText}
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
