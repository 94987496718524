import React from 'react'
import styles from './style.module.sass'

export default ({ explain }) => {
  if (!explain || explain.isHide) return null

  return (
    <div className={styles.explain} id="explain">
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{explain.title}</h2>
          {explain.buttonText && explain.buttonUrl && (
            <a href={explain.buttonUrl} className={styles.button} target="_blank">
              {explain.buttonText}
            </a>
          )}
        </div>

        <div className={styles.video}>
          <iframe
            src={`https://www.youtube.com/embed/${explain.youtubeVideoId}`}
            frameBorder="0"
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
    </div>
  )
}
