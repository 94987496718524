import React from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <img {...srcSetProps(sanityImageUrl(header.image))} alt={header.image?.caption} className={styles.image} />
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
        <p className={styles.description}>{prepareParagraph(header.description)}</p>
        <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
          {header.buttonText}
        </a>
      </div>
    </div>
  )
}
