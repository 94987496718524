import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import styles from './style.module.sass'

export default ({ navigation }) => {
  if (!navigation || navigation.isHide) return null

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const scrollTo = id => {
    gsap.to(window, {
      duration: 1,
      scrollTo: document.getElementById(id),
    })
  }

  return (
    <div className={styles.navigation}>
      <div className={styles.wrap}>
        <ul className={styles.list}>
          {navigation.items &&
            navigation.items.map(item => (
              <li className={styles.listItem} key={item._key} onClick={() => scrollTo(item.id)}>
                {item.title}
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
