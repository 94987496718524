import React from 'react'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ sex }) => {
  if (!sex || sex.isHide) return null

  return (
    <div className={styles.sex} id="sex">
      <div className={styles.wrap}>
        <h2 className={styles.title}>{sex.title}</h2>
        <p className={styles.description}>{sex.description}</p>

        <div className={styles.container}>
          <img {...srcSetProps(sanityImageUrl(sex.image))} alt={sex.image?.caption} className={styles.image} />
          <img
            {...srcSetProps(sanityImageUrl(sex.imageMobile))}
            alt={sex.imageMobile?.caption}
            className={styles.imageMobile}
          />

          <div className={styles.text}>
            <p className={styles.textTop}>{sex.productTop}</p>
            <p className={styles.textProtocol}>{sex.productTitle}</p>
            <p className={styles.textDescription}>{sex.productDescription}</p>

            {sex.buttonText && sex.buttonUrl && (
              <Link to={sex.buttonUrl} className={styles.link}>
                {sex.buttonText}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
